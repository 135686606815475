.hero_bg {
  background-image: url(../../assets/png/hero_bg_image.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_box {
  background: #162420;
  border-left: 10px solid #127ccd;
  max-width: 663px !important;
  border-radius: 20px;
  opacity: 80%;
}

.bg-gray {
  background-color: #f5f5f5;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border: 0;
}

.custom_border {
  border-top: 2px solid #127ccd !important;
}

.cusor_pointer {
  cursor: pointer !important;
}

.custom_width {
  max-width: 499px;
  width: 100% !important;
}

.bold-text {
  font-weight: bold;
}

.bg_theme {
  background-color: #f0f0f0; /* or any other background color you want */
}

.transition-effect {
  transition: font-weight 400ms ease, background-color 400ms ease;
}

.about_box {
  max-width: 260px !important;
  background-image: url(../../assets/png/about_box.png);
  position: absolute;
  bottom: -40px;
  right: 30px;
  width: 100% !important;
}

.faq_width {
  max-width: 987px !important;
}

.accordion-button {
  background-color: #fff;
  border: 0 !important;
}

.accordion-button:not(.collapsed) {
  background-color: #127ccd !important;
  color: #fff !important;
}

.cursor-pointer {
  cursor: pointer;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  border: 0 !important;
}

.custom_maxW {
  max-width: 800px !important;
}

@media (min-width: 992px) {
  .custom_w {
    width: 460px !important;
  }
}
@media (max-width: 991px) {
  .custom_w {
    max-width: 300px !important;
  }
}

@media (max-width: 768px) {
  .about_box {
    max-width: 260px !important;
    background-image: url(../../assets/png/about_box.png);
    position: absolute;
    width: 100% !important;
  }
  .about_box {
    background-image: url(../../assets/png/about_box.png);
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 100% !important;
    padding: 20px !important;
  }
}
@media (max-width: 576px) {
  .custom {
    width: 100% !important;
  }
}
