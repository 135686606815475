@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

.manrope_FF {
  font-family: "Manrope", sans-serif;
}

.heebo {
  font-family: "Heebo", sans-serif;
}

.space_grotesk {
  font-family: "Space Grotesk", sans-serif;
}
body {
  font-family: "Manrope", sans-serif !important;
}

* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-decoration: none !important;
  box-sizing: border-box;
}

:root {
  /* -------------------- Font-Size -------------------- */
  --fs-xsm: 13px;
  --fs-sm: 15px;
  --fs-md: 16px;
  --fs-lg: 17px;
  --fs-xl: 20px;
  --fs-2xl: 25px;
  --fs-3xl: 30px;
  --fs-4xl: 33px;
  --fs-5xl: 38px;
  --fs-6xl: 52px;
  --fs-7xl: 47px;
  /* ---------------------- Color ---------------------- */
  --clr-gray: #616161;
  --clr-lightgray: #a9a9a9a9;
  --clr-orange: #127ccd;
  /* ----------------- Background Color ----------------- */
  --bg-theme: #127ccd;
}

.fs_13 {
  font-size: var(--fs-xsm);
}

.fs_15 {
  font-size: var(--fs-sm);
}

.fs_16 {
  font-size: var(--fs-md);
}

.fs_17 {
  font-size: var(--fs-lg);
}

.fs_20 {
  font-size: var(--fs-xl);
}

.fs_25 {
  font-size: var(--fs-2xl);
}

.fs_30 {
  font-size: var(--fs-3xl);
}

.fs_33 {
  font-size: var(--fs-4xl);
}

.fs_38 {
  font-size: var(--fs-5xl);
}

.fs_52 {
  font-size: var(--fs-6xl);
}

.fs_47 {
  font-size: var(--fs-7xl);
}

.color_gray {
  color: var(--clr-gray) !important;
}

.color_lightgray {
  color: var(--clr-lightgray);
}

.color_orange {
  color: var(--clr-orange);
}

.bg_theme {
  background-color: var(--bg-theme) !important;
}

/* -----------------CUSTOM CSS----------------- */
.footer_bg {
  background-image: url(../src/assets/png/footer_image.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.over_box {
  background-color: #202020 !important;
}

.bg_gray {
  background-color: #444444;
}

.homefooter_bg {
  background-image: url(../src/assets/png/home_footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.max_input_width {
  max-width: 450px !important;
  width: 100%;
}

.border1 {
  border: 1px solid #2e2e2f;
}

input {
  outline: none !important;
}

select {
  outline: none !important;
}

.Subscribe_box {
  margin-bottom: -140px;
  position: relative;
  z-index: 2345678;
}

.custom_zindex {
  z-index: 1234567;
}

.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 35px;
}

.burger div {
  width: 35px;
  height: 1px;
  background-color: #127ccd;
  border: 2px solid #127ccd;
  transition: 0.3s;
  margin-top: 5px;
  margin-bottom: 5px;
}

.burger.active div:nth-child(1) {
  transform: rotate(-42deg) translate(-6px, 6px);
}

.burger.active div:nth-child(2) {
  opacity: 0;
}

.burger.active div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #2e2e2f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transition: all 400ms ease-in-out;
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 991px) {
  :root {
    /* -------------------- Font-Size -------------------- */
    --fs-xsm: 13px;
    --fs-sm: 15px;
    --fs-md: 16px;
    --fs-lg: 17px;
    --fs-xl: 20px;
    --fs-2xl: 25px;
    --fs-3xl: 30px;
    --fs-4xl: 33px;
    --fs-5xl: 38px;
    --fs-6xl: 42px;
    --fs-7xl: 30px;
  }
}

@media screen and (max-width: 768px) {
  .navmanu {
    display: block;
  }

  .d-lg-none {
    display: none;
  }

  .sidebar {
    width: 100%;
    transition: all 400ms ease-in-out;
  }
}

@media (max-width: 576px) {
  :root {
    /* -------------------- Font-Size -------------------- */
    --fs-xsm: 13px;
    --fs-sm: 15px;
    --fs-md: 16px;
    --fs-lg: 17px;
    --fs-xl: 20px;
    --fs-2xl: 25px;
    --fs-3xl: 30px;
    --fs-4xl: 33px;
    --fs-5xl: 38px;
    --fs-6xl: 28px;
    --fs-7xl: 22px;
  }
}

.z-index-1 {
  z-index: 1;
}

.width-200 {
  width: 400px !important;
}

.custom-scroll-bar {
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}
