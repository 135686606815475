.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2b2b2b;
  color: white;
  padding: 15px;
  text-align: center;
  z-index: 1000;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.cookie-consent p {
  margin: 0;
  font-size: 14px;
}

.cookie-consent a {
  color: #127ccd;
  text-decoration: underline;
}

.accept-btn {
  background-color: #127ccd;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

.accept-btn:hover {
  background-color: #127ccd;
}
